<template>
  <div style="overflow: auto;padding-bottom: 200px;">
    <!-- <van-collapse v-model="activeNames">
      <van-collapse-item :title=titleMsg >
        <van-field v-model="amount" label="文本" placeholder="请输入金额" />
        <van-field v-model="amount" type="number" label="校服费"  right-icon="edit" />
      </van-collapse-item>
    </van-collapse> -->

    <!-- <van-panel title="校服费用" desc="" @click=changeEditVis :status=titleMsg icon="edit">
      <van-field v-if=showField v-model="amount" type="number" label="校服费"  right-icon="edit" />
    </van-panel> -->

    <van-cell title="校服费用" :value="titleMsg" />
    <!-- <van-field ref="payField" v-if=showField v-model="amount" class="payField" type="number" label="校服费" @blur="editField=true"  :readonly=editField >
      <template #button>
        <van-icon name="edit"  @click="focusEditVis" />
      </template>
</van-field> -->

    <van-cell-group title="请填写下缴费信息">
      <van-cell required title="所属学校" :value="schoolName"> {{ schoolName }}</van-cell>
      <van-cell required title="所属年级" :value="gradeName" is-link @click="showGrade = true"></van-cell>
      <van-popup v-model="showGrade" round position="bottom">
        <van-picker title="所属年级" :columns="grades" @confirm="gradeConfirm" @cancel="showGrade = false" show-toolbar />
      </van-popup>
      <van-cell required title="所属班级" :value="className" is-link @click="showClass = true"></van-cell>
      <van-popup v-model="showClass" position="bottom">
        <van-picker title="所属班级" :columns="classes" @confirm="classConfirm" @cancel="showClass = false" show-toolbar />
      </van-popup>
      <div v-if="clothes.length > 0">
        <van-field v-for="(item) in clothes" :key="item.text" type="number" v-model="clothNum[item.code]"
          :label="item.text + '(' + item.price + (item.code == 2 ? '/件)' : '/套)')" placeholder="请输入件数" @blur="calcAmount" />
      </div>

      <!-- <van-field required v-model="type" label="服装类别" placeholder="请选择" /> -->
      <!--春季尺码-->
      <van-cell v-show="this.clothNum[0] > 0" required title="夏季尺码" :value="clothesSize.text" is-link
        @click="showClothesSize = true"></van-cell>
      <van-popup v-model="showClothesSize" position="bottom">
        <van-picker title="校服尺码" :columns="clothesSizes" @confirm="clothesSizeConfirm" @cancel="showClothesSize = false"
          show-toolbar />
      </van-popup>
      <!--秋季尺码-->
      <van-cell v-show="this.clothNum[1] > 0" required title="秋季尺码" :value="autumnClothesSize.text" is-link
        @click="showAutumnClothesSize = true"></van-cell>
      <van-popup v-model="showAutumnClothesSize" position="bottom">
        <van-picker title="校服尺码" :columns="autumnClothesSizes" @confirm="autumnClothesSizeConfirm"
          @cancel="showAutumnClothesSize = false" show-toolbar />
      </van-popup>
      <!--冬季尺码-->
      <van-cell v-show="this.clothNum[2] > 0" required title="冬季尺码" :value="winterClothesSize.text" is-link
        @click="showWinterClothesSize = true"></van-cell>
      <van-popup v-model="showWinterClothesSize" position="bottom">
        <van-picker title="校服尺码" :columns="winterClothesSizes" @confirm="winterClothesSizeConfirm"
          @cancel="showWinterClothesSize = false" show-toolbar />
      </van-popup>

      <div class="chima" @click="imagesSshow" v-show="this.clothNum[0] > 0 || this.clothNum[1] > 0 || this.clothNum[2] > 0">
        查看尺码标准
      </div>

      <van-image-preview v-model="imagesShow" :images="images" @change="onImagesChange">
        <template slot="imagesIndex"></template>
      </van-image-preview>

      <van-cell required title="学生性别" :value="clothesSex.text" is-link @click="showSex = true"></van-cell>
      <van-popup v-model="showSex" position="bottom">
        <van-picker title="学生性别" :columns="sexEnum" @confirm="clothesSexConfirm" @cancel="showSex = false"
          show-toolbar />
      </van-popup>
      <van-field required v-model="studentName" label="学生姓名" placeholder="请输入用户名" />
      <van-field required v-model="conactNo" label="联系方式" type="tel" placeholder="请输入手机号" />
      <van-field v-model="studentNo" label="班级学号" type="number" maxlength="6" placeholder="请输入班级学号" />
      <van-field v-model="address" label="收货地址" type="text" placeholder="请输入收货地址" />
    </van-cell-group>



    <van-cell-group title="请选中支付方式">

      <van-cell title="微信支付" icon="wechat" clickable @click="radio = '1'">
        <template #right-icon>
          <van-radio checked />
        </template>
      </van-cell>
    </van-cell-group>




    <div class="bottomLayout">
      <van-button class="submitBtn" type="primary" size="large" @click="createOrder">缴费</van-button>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';
import request from '../utils/request';

export default {
  name: 'OrderPay',
  data() {
    return {
      sexEnum: [
        { "text": "男", "value": 0 },
        { "text": "女", "value": 1 }
      ],
      accessCode: '',
      activeNames: ['1'],
      userId:0,
      amount: 0,
      schoolId: 0,
      schoolName: '',
      gradeName: '',
      className: '',
      classId: '',
      clothesId: '',
      studentName: '',
      conactNo: '',
      studentNo: '',
      type: '',
      typeName: '',
      checked: '1',
      clothNum: [0, 0, 0],
      address: '',
      clothesSize: { "text": "", "value": "" },
      autumnClothesSize: { "text": "", "value": "" },
      winterClothesSize: { "text": "", "value": "" },
      clothesSex: { "text": "男", "value": 0 },

      showPay: false,

      showField: false,
      editField: true,
      focusState: false,

      showGrade: false,
      showClass: false,
      showClothes: false,
      showClothesSize: false,
      showAutumnClothesSize: false,
      showWinterClothesSize: false,
      showSex: false,

      gradeData: {},
      grades: [],
      classes: [],
      clothesSizes: [],
      autumnClothesSizes: [],
      winterClothesSizes: [],
      commonClothesSizes: [],
      clothes: [],
      clothPrice: {},
      clothTypeId: {},
      imagesShow: false,
      images: [''],
      imagesIndex: 0
    }
  },
  updated() {
    if (this.editField == false) {
      if (this.amount == 0) {
        this.amount = '';
      }
      this.$refs.payField.focus();
    }

  },
  computed: {
    titleMsg: function () {
      // `this` points to the vm instance
      return "￥" + this.amount;
    },
    classAllName: function () {
      if (this.schoolName != '') {
        return this.schoolName + "|" + this.gradeName + "|" + this.className;
      }
      return '';
    }
  },
  created() {

  },
  mounted() {
    // alert("query"  + this.$route.query.code )
    if (this.userId!=0 && this.accessCode!='') {

    } else {
      if (!this.$route.query.code) {
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc27cd5a3d5c45c0c&redirect_uri=http%3A%2F%2Fwww.kuhuashi.cn%2F%23%2Findex&response_type=code&scope=snsapi_base&state=" + this.$route.query.schoolId + "#wechat_redirect";
        return;
      } else {
        this.accessCode = this.$route.query.code

        if (this.$route.query.state) {
          this.schoolId = this.$route.query.state;
        }
        localStorage.setItem("schoolId", this.schoolId)
        localStorage.setItem("accessCode", this.accessCode)

      }
    }
    this.initData()

    // this.accessCode = localStorage.getItem("accessCode")
    // this.schoolId = localStorage.getItem("schoolId")
    // this.userId = localStorage.getItem("userId")
    // // if(this.schoolId==0){
    //   alert("userId " + this.userId )
    // alert("schoolId " + this.schoolId )
    // alert("accessCode " + this.accessCode )
    // // }
    //   this.initData();
  },
  methods: {
    initData() {
      if (this.userId!=0) {

      } else {
        request({
          method: 'GET',
          url: '/auth/callWechatCode/' + this.accessCode,
        }).then((response) => {
          // alert(response)
          this.userId = response.data.data.userId
          localStorage.setItem("userId", this.userId)
        });
      }

      request({
        method: 'GET',
        url: '/class-group/getSchoolInfo/' + this.schoolId,
      }).then((response) => {
        let schInfo = response.data.data;
        this.schoolName = schInfo.name;
      })

      request({
        method: 'GET',
        url: '/class-group/getClassTree/' + this.schoolId,
      }).then((response) => {
        this.gradeData = response.data.data;
        let keys = Object.keys(this.gradeData);
        if(keys.length>0)
        for (var i = 0; i < keys.length; i++) {
          let node = this.gradeData[keys[i]]
          // console.log("node:"+node)
          this.grades[node[0].oldId - 1] = node[0].gradeName;
        }
      })

      request({
        method: 'POST',
        url: '/class-group/getClothesSize',
        data: {
          'schId': this.schoolId
        }
      }).then((response) => {
        if (response.data.code != 0) {
          Toast(response.message);
          return
        }
        let clothesSizeRep = response.data.data
        for (var i = 0; i < clothesSizeRep.length; i++) {
          var size = {}
          if (clothesSizeRep[i].season == 1) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.clothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 2) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.autumnClothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 3) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.winterClothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 9) {
            console.log("commonClothesSizes")
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.commonClothesSizes.push(size);
          }
        }
        if (this.clothesSizes.length == 0) {
          this.clothesSizes = this.commonClothesSizes
        }
        if (this.autumnClothesSizes.length == 0) {
          this.autumnClothesSizes = this.commonClothesSizes
        }
        if (this.winterClothesSizes.length == 0) {
          this.winterClothesSizes = this.commonClothesSizes
        }
      })

    },
    createOrder() {
      if (this.gradeName == '') {
        Toast("提交失败：请选择年级信息！")
        return
      }
      if (this.className == '') {
        Toast("提交失败：请选择班级信息！");
        return
      }

      if (this.studentName == '') {
        Toast("提交失败：请选择学生姓名！");
        return
      }
      if (this.conactNo == '') {
        Toast("提交失败：请选择联系方式！");
        return
      }
      if (typeof (this.clothNum) == "undefined" || this.clothNum == null || this.clothNum.length == 0) {
        Toast("提交失败：请选择服装数量！");
        return
      }
      if (this.clothNum[0] < 1 && this.clothNum[1] < 1 && this.clothNum[2] < 1) {
        Toast("提交失败：请选择服装数量！");
        return
      }

      if(this.clothNum[0]>0   ){
        if (typeof (this.clothesSize.text) == "undefined" || this.clothesSize.text == null || this.clothesSize.text == '') { 
          Toast("提交失败：请选择夏装尺码！");
          return
        }
      }
      if(this.clothNum[1]>0   ) {
        if (typeof (this.autumnClothesSize.text) == "undefined" || this.autumnClothesSize.text == null || this.autumnClothesSize.text == '') {
          Toast("提交失败：请选择秋装尺码！");
          return
        }
      }
      if(this.clothNum[2]>0   ) {
        if (typeof (this.winterClothesSize.text) == "undefined" || this.winterClothesSize.text == null || this.winterClothesSize.text == '') { 
          Toast("提交失败：请选择冬装尺码！");
          return
        }
      }

      request({
        method: 'POST',
        url: '/order/createOrder',
        data: {
          "studentName": this.studentName,
          "studentNo": this.studentNo,
          "contactNo": this.conactNo,
          "classId": this.classId,
          "code": this.accessCode,
          "summerNum": this.clothNum[0],
          "autumnNum": this.clothNum[1],
          "winterNum": this.clothNum[2],
          "address": this.address,
          "clothesSize": this.clothesSize.text,
          "autumnClothesSize": this.autumnClothesSize.text,
          "winterClothesSize": this.winterClothesSize.text,
          "sex": this.clothesSex.value,
          "userId": localStorage.getItem("userId")
        }
      }).then((response) => {
        if (response.data.code == "0") {
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            this.onBridgeReady(response.data.data);
          }
        } else {
          Toast("下单失败！");
        }
      })
    },
    onBridgeReady(param) {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appID": param.AppID,     //公众号ID，由商户传入
        "appid": param.AppID,
        "appId": param.AppID,
        "timeStamp": param.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": param.nonceStr,      //随机串
        "package": param.package,
        "signType": "RSA",     //微信签名方式：
        "paySign": param.paySign     //微信签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast("缴费成功")
          } else {
            Toast("缴费失败：" + res.err_msg)
          }
        });
    },
    gradeConfirm(value) {
      this.classes = [];
      this.clothes = []
      if (this.gradeName == '' || this.gradeName != value) {
        let classDatas = this.gradeData[value]
        for (var i = 0; i < classDatas.length; i++) {
          var classD = {}
          classD['text'] = classDatas[i].name;
          classD['value'] = classDatas[i].id;
          this.classes.push(classD);
        }
        this.classId = '';
        this.className = '';
        this.type = '';
        this.typeName = '';
      }

      this.gradeName = value;
      this.showGrade = false;

      this.amount = 0;
      this.clothesId = '';
    },

    classConfirm(node) {
      this.clothes = []
      let value = node.value;

      if (this.classId == '' || this.classId != value.toString()) {
        for (var clsKey in this.gradeData[this.gradeName]) {
          let cls = this.gradeData[this.gradeName][clsKey]
          if (cls.id == value) {
            let clothesInfos = cls.clothesInfoList;
            for (var i = 0; i < clothesInfos.length; i++) {
              let cloth = {};
              cloth['value'] = clothesInfos[i]['clothType'];
              if (clothesInfos[i]['clothType'] == 0) {
                cloth['text'] = '夏服';
                cloth['code'] = 0;
                cloth['price'] = clothesInfos[i]['price'];
              } else if (clothesInfos[i]['clothType'] == 1) {
                cloth['text'] = '秋服';
                cloth['code'] = 1;
                cloth['price'] = clothesInfos[i]['price'];
              } else {
                cloth['text'] = '冬服';
                cloth['code'] = 2;
                cloth['price'] = clothesInfos[i]['price'];
              }
              this.clothPrice[clothesInfos[i]['clothType']] = clothesInfos[i]['price']
              this.clothTypeId[clothesInfos[i]['clothType']] = clothesInfos[i]['id']
              this.clothes.push(cloth)
            }
            break;
          }
        }
        this.classId = value;
        this.type = '';
        this.typeName = '';
        // this.amount = '';
        this.clothesId = '';
      }

      this.className = node.text;
      this.showClass = false;
    },

    clothesSizeConfirm(node) {
      this.clothesSize = node;
      this.showClothesSize = false;
    },

    autumnClothesSizeConfirm(node) {
      this.autumnClothesSize = node;
      this.showAutumnClothesSize = false;
    },

    winterClothesSizeConfirm(node) {
      this.winterClothesSize = node;
      this.showWinterClothesSize = false;
    },

    clothesSexConfirm(node) {
      this.clothesSex = node;
      this.showSex = false;
    },

    changeEditVis() {
      this.showField = !this.showField;
      Toast("提示信息：" + this.showField)
    },
    focusEditVis() {
      this.editField = false;
    },
    calcAmount() {
      this.amount = 0
      if (this.clothNum[0]) {
        this.amount += this.clothPrice[0] * this.clothNum[0]
      }
      if (this.clothNum[1]) {
        this.amount += this.clothPrice[1] * this.clothNum[1]
      }
      if (this.clothNum[2]) {
        this.amount += this.clothPrice[2] * this.clothNum[2]
      }
    },
    imagesSshow() {
      // console.log(this.$route.query.state)
      // let sdd = request('../assets/1.jpg')
      this.images[0] = './src/assets/' + this.$route.query.state + '.jpg'
      this.imagesShow = true
    },
    onImagesChange(index) {
      this.imagesIndex = index;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bannerTitle {
  margin-left: 10px;
}

.payField {
  margin-left: 10px;
  width: 95%;
}

.bottomLayout {
  box-sizing: border-box;
  padding: 10px 10px;
  /*padding-bottom: 200px;*/
  margin-top: 20px;
  height: 40px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 22px;
  align-items: center;
  justify-content: space-between;
  /*position: sticky;*/
  bottom: 10px;
  background: white;
}

.submitBtn {
  height: 40px;
  background: #1883fd;
  border-radius: 6px;
  color: white;
  flex-grow: 2;
}

.chima {
  font-size: 14px;
  color: #1883fd;
  padding-left: 15px;
  margin-top: 10px;
}
</style>
