// 导入组件
import Vue from 'vue';
import Router from 'vue-router';


import HelloWorld from '@/components/HelloWorld.vue';
import OrderPay from '@/components/OrderPay.vue';


// 启用路由
Vue.use(Router);

// 导出路由
const router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'OrderPay',
        component: OrderPay,
        meta: {
            showTab: true
        }
    },

        {
            path: '/hello',
            name: 'HelloWorld',
            component: HelloWorld,
            meta: {
                showTab: true
            }
        }]
})

export default router;
