<template>
  <div id="app" style="height: 100%">
    <router-view/>
    <van-tabbar route active-color="#07c160" v-if="$route.meta.showTab">
      <van-tabbar-item name="orderPay" icon="home-o" to="/">下单</van-tabbar-item>
      <van-tabbar-item name="orderList" icon="search" to="/hello">我的订单</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'App',
  data(){
return {
  schoolId:0,
  accessCode:'',
  userId:0
}
  },
  beforeCreate() {

  },
  methods:{
    initData(){

    }
  }
}
</script>

<style>
@import "./css/index.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* margin-top: 60px; */
}
</style>
